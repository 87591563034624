import * as z from 'zod'
import { t } from 'i18next'

export const teacherPricingSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  description: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
})

export const teacherBecomeInstructorSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  description: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  ctaButtonName: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  ctaButtonNameInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
})

export const TeacherSEOConfigureSchema = z.object({
  metaTitle: z.string().nonempty(),
  keyWords: z.string().nonempty(),
  metaDescription: z.string().nonempty(),
  canonical: z.string().nonempty(),
  metaImage: z.string().nonempty(),
})

export const TEACHER_PRICING_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  description: '',
  descriptionInDutch: '',
}

export const TEACHER_BECOME_INSTRUCTOR_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  description: '',
  descriptionInDutch: '',
  ctaButtonName: '',
  ctaButtonNameInDutch: '',
}

export const TEACHER_SEO_CONFIGURE_DEFAULT_VALUES = {
  metaTitle: '',
  keyWords: '',
  metaDescription: '',
  canonical: '',
  metaImage: '',
}
