import React from 'react'

type TeacherFAQProps = {
  activeTab: string
}

export const TeacherFAQ = (props: TeacherFAQProps) => {
  return (
    <div>TeacherFAQ</div>
  )
}