import { memo, useRef, useState } from 'react'
import { t } from 'i18next'
import { Typography } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'

import { AppModal } from '@components'
import { Button, HookFormTextArea, Input } from 'src/common'
import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { HOW_TO_DETAIL_DEFAULT_VALUES, howToDetailSchema } from './configs'

interface IProps {
  howToId?: number | string
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const HowToDetailModal = memo(
  ({ open, onClose, onSubmit, howToId }: IProps) => {
    const [isModalLoading, setIsModalLoading] = useState(false)
    const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
    const [selectedField, setSelectedField] = useState<
      'desktop' | 'mobile' | null
    >(null)
    const [imageSrc, setImageSrc] = useState('')
    const [imageMobileSrc, setImageMobileSrc] = useState('')
    const onChangeDesktopRef = useRef<any>(null)
    const onChangeMobileRef = useRef<any>(null)

    const { control, handleSubmit, reset, setValue, getValues } = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: zodResolver(howToDetailSchema),
      defaultValues: HOW_TO_DETAIL_DEFAULT_VALUES,
    })

    const onCloseModalSelectMedia = () => {
      setShowModalSelectMedia(false)
    }

    const onSelectMedia = (data: IMediaItem) => {
      if (selectedField === 'desktop') {
        setValue('mediaId', data?.id ?? 0)
        onChangeDesktopRef.current(data?.id ?? 0)
        setImageSrc(data?.baseUrl ?? '')
      } else if (selectedField === 'mobile') {
        setValue('mediaMobileId', data?.id ?? 0)
        onChangeMobileRef.current(data?.id ?? 0)
        setImageMobileSrc(data?.baseUrl ?? '')
      }

      setShowModalSelectMedia(false)
    }

    return (
      <>
        {showModalSelectMedia ? (
          <>
            <SelectMediaModal
              isOpen={showModalSelectMedia}
              onClose={onCloseModalSelectMedia}
              mediaType={EMediaType.IMAGE}
              category={EMediaCategory.CONTENT}
              onSelectMedia={onSelectMedia}
              modalClassName="!z-[110]"
            />
          </>
        ) : null}
        <AppModal
          open={open}
          onClose={onClose}
          haveCloseIcon
          title={
            <Typography.Title level={3} className="!m-0 !text-[32px]">
              {t('teacher:how_to_detail')}
            </Typography.Title>
          }
          contentClassName="!min-w-[900px]"
        >
          <HowToDetailStyled>
            <Controller
              name="no"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="No"
                    type="number"
                    value={value}
                    onChange={onChange}
                    label="No"
                    alignment="col"
                    errors={error?.message}
                    required
                    min={1}
                  />
                )
              }}
            />
            <div className="col-span-1"></div>
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Title"
                    value={value}
                    onChange={onChange}
                    label="Title"
                    alignment="col"
                    errors={error?.message}
                    required
                  />
                )
              }}
            />
            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Title in Netherlands"
                    value={value}
                    onChange={onChange}
                    label="Title in Netherlands"
                    alignment="col"
                    errors={error?.message}
                    required
                  />
                )
              }}
            />

            <HookFormTextArea
              control={control}
              placeholder="Description"
              controlName="description"
              label="Description"
              rows={5}
            />

            <HookFormTextArea
              control={control}
              placeholder="Description in Netherlands"
              controlName="descriptionInDutch"
              label="Description in Netherlands"
              rows={5}
            />

            <MediaUploaderStyled>
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image Web"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="!w-full mt-3"
                      labelClassName="!w-full"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setImageSrc((prev) => data?.original ?? prev)
                      }}
                      initResource={imageSrc}
                      errors={error?.message}
                      reset={getValues('mediaId') === 0}
                      limitFileSize={5}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                }}
              />
            </MediaUploaderStyled>

            <MediaUploaderStyled>
              <Controller
                name={'mediaMobileId'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image mobile"
                      name="mediaMobileId"
                      id="mediaMobileId"
                      containerClassName="!w-full mt-3"
                      labelClassName="!w-full"
                      alignment="col"
                      onLoadEnd={(data) => {
                        setValue('mediaMobileId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setImageMobileSrc((prev) => data?.original ?? prev)
                      }}
                      required
                      initResource={imageMobileSrc}
                      errors={error?.message}
                      multiple={false}
                      reset={getValues('mediaMobileId') === 0}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                }}
              />
            </MediaUploaderStyled>

            <ButtonWrapper className="w-full col-span-2 !justify-center !items-center">
              <Button
                size="small"
                className="!text-[16px] !text-white !bg-gray-400 !border-none"
                onClick={onClose}
              >
                {t('common:cancel')}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                className="!text-[16px] !text-white !border-none"
                onClick={handleSubmit(onSubmit)}
                loading={isModalLoading}
              >
                {t('common:save')}
              </Button>
            </ButtonWrapper>
          </HowToDetailStyled>
        </AppModal>
      </>
    )
  }
)

const HowToDetailStyled = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
const MediaUploaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`
