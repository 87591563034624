import { zodResolver } from '@hookform/resolvers/zod'
import { TEACHER_PRICING_DEFAULT_VALUES, teacherPricingSchema } from '@pages'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'

type TeacherPricingProps = {
  activeTab: string
}
export const TeacherPricing = (props: TeacherPricingProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: TEACHER_PRICING_DEFAULT_VALUES,
    resolver: zodResolver(teacherPricingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })


  const handleConfirmSubmit = (data: any) => {
    console.log('data', data)
  }

  return (
    <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
      <div className="intro-y box p-5 bg-white rounded-lg">
        <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
          <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
            Pricing
          </h2>
        </div>

        {/* Content */}

        <div className="blog-content-main intro-y mt-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        name="title"
                        placeholder="Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="titleInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Title in Netherlands"
                        value={value}
                        onChange={onChange}
                        required
                        label="Title in Netherlands"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="subTitle"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Sub Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Sub Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="subTitleInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Sub Title in Netherlands"
                        value={value}
                        onChange={onChange}
                        required
                        label="Sub Title in Netherlands"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        placeholder="Description"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Description"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="descriptionInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        placeholder="Description in Netherlands"
                        value={value}
                        onChange={onChange}
                        required
                        label="Description in Netherlands"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        

        {/* Button */}

        <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
          <Button
            type="default"
            size="small"
            id="blog-form"
            onClick={() => {
              console.log(231231231)
            }}
          >
            {t('common:cancel')}
          </Button>

          <Button
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              handleSubmit(handleConfirmSubmit)()
            }}
          >
            {t('common:save')}
          </Button>
        </div>
      </div>
    </div>
  )
}
