import {
  AboutUsLanding,
  IAboutUsPageSeo,
} from './../../interfaces/aboutus-management'
import { createSlice } from '@reduxjs/toolkit'

import {
  IAddNewCourse,
  ICourseItem,
  IListMessageWithUser,
  IMessageAttachmentData,
  IResponseGetCourseDetail,
} from 'src/interfaces/course'
import { RootState } from '.'

import {
  checkDeletedCourse,
  createLandingCourseAction,
  duplicateCourseByIdAction,
  // addNewCourseAction,  //TODO
  // deleteCourseByIdAction,  //TODO
  getCourseDetailAction,
  getCoursePageSeoAction,
  getLandingCourseAction,
  getListChat,
  getListChatWithUser,
  getListCourseAction,
  highLightByIdAction,
  updateCoursePageSeoAction,
  uploadMessageAttachment,
  // updateCourseByIdAction,  //TODO
  // uploadThumbnailAction,
} from '../actions/course'
import { uniqBy } from 'lodash'

interface ICourseState {
  courses: ICourseItem[]
  landing: AboutUsLanding | undefined
  totalHighlight: number
  totalCourses: number
  pageCourses: number | string
  limitCourses: number | string
  selectedCourse?: IResponseGetCourseDetail
  loadings: Record<string, boolean | undefined>
  newCourse?: IAddNewCourse
  aboutUsPageSeo: IAboutUsPageSeo | undefined
  listMessageWithUser: IListMessageWithUser
  messageAttachment: IMessageAttachmentData
  listChat: {
    data: any
    isLoading: boolean
    total: number
  }
  page: number
  isCourseDeleted: boolean
  sendingMessageIds: string[]
  error: any
}

const initialState: ICourseState = {
  landing: undefined,
  courses: [],
  totalCourses: 0,
  pageCourses: 0,
  limitCourses: 0,
  totalHighlight: 0,
  selectedCourse: {} as IResponseGetCourseDetail,
  loadings: {},
  newCourse: {} as IAddNewCourse,
  aboutUsPageSeo: undefined,
  listMessageWithUser: {
    data: [],
    isLoading: false,
    total: 0,
  },
  listChat: {
    data: [],
    isLoading: false,
    total: 0,
  },
  messageAttachment: {
    data: {},
    isLoading: false,
  },
  page: 1,
  isCourseDeleted: false,
  sendingMessageIds: [],
  error: {} as any,
}

const courseSlice = createSlice({
  name: 'course',
  initialState: initialState,
  reducers: {
    resetListMessageWithUser: (state) => {
      state.listMessageWithUser.data = []
      state.listMessageWithUser.isLoading = false
    },
    addNewMessageToChat: (state, action) => {
      state.listMessageWithUser.data = []
        .concat(action.payload?.message)
        .concat(
          action?.payload?.message?.isReceived
            ? state.listMessageWithUser?.data?.filter((it) => {
                if (!it?.tempId) return it
                return it?.tempId !== action?.payload?.message?.tempId
              })
            : (state.listMessageWithUser?.data as any)
        )
    },
    updateLastestMessage: (state, action) => {
      if (!action.payload?.senderId) return

      const updatedItemIndex = state.listChat.data?.findIndex(
        (it: any) =>
          String(it?.enrollCourseId) === String(action.payload?.senderId)
      )

      if (updatedItemIndex < 0) {
        state.listChat.data = [].concat(state.listChat.data).concat([
          {
            content: action?.payload?.lastestMessage,
            createdAt: action?.payload?.createdAt,
            unreadMessages: 1,
            isRead: false,
            enrollCourse: {
              user: action?.payload?.user,
            },
            user: action?.payload?.user,
            userId: action?.payload?.userId,
            type: action?.payload?.type,
            enrollCourseId: action?.payload?.enrollCourseId,
          },
        ] as any)
        return
      }

      state.listChat.data = state.listChat.data?.map((it: any, idx: number) => {
        if (idx === updatedItemIndex)
          return {
            ...it,
            content: action?.payload?.lastestMessage,
            createdAt: action?.payload?.createdAt,
            unreadMessages: action?.payload?.isRead
              ? 0
              : it?.unreadMessages + 1,
            isRead: action?.payload?.isRead,
            user: action?.payload?.user,
            userId: action?.payload?.userId,
            type: action?.payload?.type,
          }

        return it
      })
    },
    updateUnreadNumber: (state, action) => {
      if (!action.payload?.senderId) return

      const updatedItemIndex = state.listChat.data?.findIndex(
        (it: any) =>
          String(it?.enrollCourseId) === String(action.payload?.senderId)
      )

      state.listChat.data = state.listChat.data?.map((it: any, idx: number) => {
        if (idx === updatedItemIndex)
          return {
            ...it,
            unreadMessages: 0,
          }

        return it
      })
    },
    setPage: (state, action) => {
      state.page = action?.payload?.page || 0
    },
    resetListChat: (state) => {
      state.listChat.data = []
    },
    addNewSendingMessageId: (state, action) => {
      state.sendingMessageIds = ([] as string[])
        .concat(action?.payload?.sendingMessageId)
        .concat(state.sendingMessageIds)
    },
    removeSendingMessageId: (state, action) => {
      state.sendingMessageIds = state.sendingMessageIds?.filter(
        (id) => id !== action?.payload?.sendingMessageId
      )
    },
    removeSendingFailedMessageById: (state, action) => {
      state.sendingMessageIds = state.sendingMessageIds?.filter(
        (id) => id !== action?.payload?.sendingMessageId
      )
      state.listMessageWithUser.data = state.listMessageWithUser.data?.filter(
        (message) => message?.tempId !== action?.payload?.sendingMessageId
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListCourseAction.pending, (state) => {
      state.loadings[`getListCourseActionLoading`] = true
    })
    builder.addCase(getListCourseAction.fulfilled, (state, action) => {
      state.loadings[`getListCourseActionLoading`] = false
      state.courses = action.payload.data.items
      state.totalCourses = action.payload.data.total
      state.totalHighlight = action.payload.data?.totalHighlight || 0
      state.pageCourses = action.payload.data.page
      state.limitCourses = action.payload.data.limit
    })
    builder.addCase(getListCourseAction.rejected, (state) => {
      state.loadings[`getListCourseActionLoading`] = false
    })
    builder.addCase(getLandingCourseAction.pending, (state) => {
      state.loadings[`getLandingCourseActionLoading`] = true
    })
    builder.addCase(getLandingCourseAction.fulfilled, (state, action) => {
      state.loadings[`getLandingCourseActionLoading`] = false
      state.landing = action.payload.data
    })
    builder.addCase(getLandingCourseAction.rejected, (state) => {
      state.loadings[`getLandingCourseActionLoading`] = false
    })
    builder.addCase(getCourseDetailAction.pending, (state) => {
      state.error = {} as any
      state.loadings[`getCourseDetailActionLoading`] = true
    })
    builder.addCase(getCourseDetailAction.fulfilled, (state, action) => {
      state.loadings[`getCourseDetailActionLoading`] = false
      state.selectedCourse = action.payload.data
    })
    builder.addCase(getCourseDetailAction.rejected, (state, action) => {
      state.loadings[`getCourseDetailActionLoading`] = false
      state.error = action.payload
    })
    builder.addCase(createLandingCourseAction.rejected, (state) => {
      state.loadings[`createLandingCourseActionLoading`] = false
    })
    builder.addCase(createLandingCourseAction.fulfilled, (state, action) => {
      state.loadings[`createLandingCourseActionLoading`] = false
      state.landing = action.payload.data
    })
    builder.addCase(createLandingCourseAction.pending, (state) => {
      state.loadings[`createLandingActionLoading`] = true
    })
    builder.addCase(highLightByIdAction.rejected, (state) => {
      state.loadings[`highLightByIdActionLoading`] = false
    })
    builder.addCase(highLightByIdAction.fulfilled, (state, action) => {
      const currentCourses = [...state.courses]
      const currentHighlightCourses = (action.payload.data as any)
        .highlightCourses

      state.totalHighlight = currentHighlightCourses.length

      state.courses = currentCourses.map((course) => {
        if (course.id === +action.meta?.arg) {
          return {
            ...course,
            isHighlighted: !course.isHighlighted,
          }
        } else {
          return {
            ...course,
            isHighlighted: currentHighlightCourses.includes(course.id),
          }
        }
      })
    })
    builder.addCase(highLightByIdAction.pending, (state) => {
      state.loadings[`createLandingActionLoading`] = true
    })
    // TODO
    // builder.addCase(updateCourseByIdAction.pending, (state) => {
    //   state.loadings[`updateCourseByIdActionLoading`] = true
    // })
    // builder.addCase(updateCourseByIdAction.fulfilled, (state, action) => {
    //   state.loadings[`updateCourseByIdActionLoading`] = false
    // })
    // builder.addCase(updateCourseByIdAction.rejected, (state) => {
    //   state.loadings[`updateCourseByIdActionLoading`] = false
    // })
    // builder.addCase(deleteCourseByIdAction.pending, (state) => {
    //   state.loadings[`deleteCourseByIdActionLoading`] = true
    // })
    // builder.addCase(deleteCourseByIdAction.fulfilled, (state, action) => {
    //   state.loadings[`deleteCourseByIdActionLoading`] = false
    // })
    // builder.addCase(deleteCourseByIdAction.rejected, (state) => {
    //   state.loadings[`deleteCourseByIdActionLoading`] = false
    // })
    builder.addCase(getCoursePageSeoAction.pending, (state) => {
      state.loadings[`getCoursePageSeoActionLoading`] = true
    })
    builder.addCase(getCoursePageSeoAction.fulfilled, (state, action) => {
      state.loadings[`getCoursePageSeoActionLoading`] = false
      state.aboutUsPageSeo = action.payload.data
    })
    builder.addCase(getCoursePageSeoAction.rejected, (state) => {
      state.loadings[`getCoursePageSeoActionLoading`] = false
    })
    builder.addCase(updateCoursePageSeoAction.pending, (state) => {
      state.loadings[`updateCoursePageSeoActionLoading`] = true
    })
    builder.addCase(updateCoursePageSeoAction.fulfilled, (state, action) => {
      state.loadings[`updateCoursePageSeoActionLoading`] = false
      state.aboutUsPageSeo = action.payload.data?.seoAboutUs?.metaData
    })
    builder.addCase(updateCoursePageSeoAction.rejected, (state) => {
      state.loadings[`updateCoursePageSeoActionLoading`] = false
    })
    builder.addCase(duplicateCourseByIdAction.pending, (state) => {
      state.loadings[`duplicateCourseByIdActionLoading`] = true
    })
    builder.addCase(duplicateCourseByIdAction.fulfilled, (state, action) => {
      state.loadings[`duplicateCourseByIdActionLoading`] = false
    })
    builder.addCase(duplicateCourseByIdAction.rejected, (state) => {
      state.loadings[`getCourseDetailActionLoading`] = false
    })
    builder.addCase(getListChatWithUser.pending, (state) => {
      state.listMessageWithUser.isLoading = true
    })
    builder.addCase(getListChatWithUser.fulfilled, (state, action: any) => {
      state.listMessageWithUser.isLoading = false
      state.listMessageWithUser.data = []
        .concat(state.listMessageWithUser.data as any)
        .concat(action.payload?.items)
      state.listMessageWithUser.total = action?.payload?.total
    })
    builder.addCase(getListChatWithUser.rejected, (state) => {
      state.listMessageWithUser.isLoading = false
    })
    builder.addCase(uploadMessageAttachment.pending, (state) => {
      state.messageAttachment.isLoading = true
    })
    builder.addCase(uploadMessageAttachment.fulfilled, (state, action: any) => {
      state.messageAttachment.isLoading = false
      state.messageAttachment.data = action.payload
    })
    builder.addCase(uploadMessageAttachment.rejected, (state) => {
      state.messageAttachment.isLoading = false
      state.messageAttachment.data = {}
    })
    builder.addCase(getListChat.pending, (state) => {
      state.listChat.isLoading = true
    })
    builder.addCase(getListChat.fulfilled, (state, action: any) => {
      state.listChat.isLoading = false
      state.listChat.data = uniqBy(
        [].concat(state.listChat.data).concat(action.payload?.items),
        'enrollCourseId'
      )
      state.listChat.total = action?.payload?.total
    })
    builder.addCase(getListChat.rejected, (state) => {
      state.listChat.isLoading = false
    })
    builder.addCase(checkDeletedCourse.pending, (state) => {
      state.isCourseDeleted = false
      state.loadings[`checkDeletedCourseActionLoading`] = true
    })
    builder.addCase(checkDeletedCourse.fulfilled, (state, action) => {
      state.isCourseDeleted = !!action.payload?.deletedAt || false
      state.loadings[`checkDeletedCourseActionLoading`] = false
    })
    builder.addCase(checkDeletedCourse.rejected, (state) => {
      state.isCourseDeleted = false
      state.loadings[`checkDeletedCourseActionLoading`] = false
    })
  },
})

export const courseActions = {
  ...courseSlice.actions,
}

export const {
  resetListMessageWithUser,
  addNewMessageToChat,
  updateLastestMessage,
  setPage,
  resetListChat,
  updateUnreadNumber,
  addNewSendingMessageId,
  removeSendingMessageId,
  removeSendingFailedMessageById,
} = courseSlice.actions

export const selectCourseLoading = (state: RootState, name: string) =>
  state.course.loadings?.[`${name}Loading`]
export default courseSlice.reducer
