import { t } from 'i18next'
import * as z from 'zod'

export const DEFAULT_TEACHER_VIDEO_INTRO = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  mediaDescription: '',
  mediaDescriptionInDutch: '',
  mediaId: 0,
  mediaMobileId: 0,
}

export const teacherVideoIntroSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:landing_title_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:landing_title_in_dutch_length_error') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  mediaDescription: z
    .string()
    .trim()
    .max(500, {
      message: t('error:landing_description_length_error') as string,
    }),
  mediaDescriptionInDutch: z
    .string()
    .trim()
    .max(500, {
      message: t('error:landing_description_length_error') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})
