import { zodResolver } from '@hookform/resolvers/zod'
import {
  TEACHER_SEO_CONFIGURE_DEFAULT_VALUES,
  TeacherSEOConfigureSchema,
} from '@pages'
import { t } from 'i18next'
import { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import { EMediaType, EMediaCategory } from 'src/interfaces/gallery'

type TeacherSEOConfigureProps = {
  activeTab: string
}
export const TeacherSEOConfigure = (props: TeacherSEOConfigureProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: TEACHER_SEO_CONFIGURE_DEFAULT_VALUES,
    resolver: zodResolver(TeacherSEOConfigureSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangeDesktopRef = useRef<any>(null)
  const [imageSrc, setImageSrc] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)

  const handleConfirmSubmit = (data: any) => {
    console.log('data', data)
  }

  return (
    <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
      <div className="intro-y box p-5 bg-white rounded-lg">
        <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
          <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
            Become Instructor
          </h2>
        </div>

        {/* Content */}

        <div className="blog-content-main intro-y mt-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="metaTitle"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        name="Meta Title"
                        placeholder="Meta Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Meta Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="keyWords"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Key words"
                        value={value}
                        onChange={onChange}
                        required
                        label="Key words"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="metaDescription"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Meta Description"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Meta Description"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="canonical"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Canonical"
                        value={value}
                        onChange={onChange}
                        required
                        label="Canonical"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="metaImage"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        labelClassName="w-full"
                        label="Meta Image"
                        name="mediaId"
                        id="mediaId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        errors={error?.message}
                        initResource={imageSrc}
                        reset={getValues('mediaId') === 0}
                        limitFileSize={5}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        changeLoading={setIsModalLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
          <Button
            type="default"
            size="small"
            id="blog-form"
            onClick={() => {
              console.log(231231231)
            }}
          >
            {t('common:cancel')}
          </Button>

          <Button
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              handleSubmit(handleConfirmSubmit)()
            }}
          >
            {t('common:save')}
          </Button>
        </div>
      </div>
    </div>
  )
}
