import { upperCase } from 'lodash'
import { t } from 'i18next'
import * as z from 'zod'
import { ColumnsType } from 'antd/lib/table'
import { Typography } from 'antd'

import { TableTitleWrapperStyled } from '../../styled'
import { IMedia } from 'src/interfaces/course'

export const HOW_TO_TABLE_COLUMNS: ColumnsType<any> = [
  {
    width: '15%',
    title: upperCase(t('dashboard:no')),
    dataIndex: 'no',
    key: 'no',
    ellipsis: true,
    align: 'center',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '50%',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:title')}</p>
        </TableTitleWrapperStyled>
      )
    },
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '20%',
    align: 'center',
    dataIndex: 'media',
    key: 'media',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:image')}</p>
        </TableTitleWrapperStyled>
      )
    },
    render: (media: IMedia) => {
      return (
        <div className="flex items-center justify-center w-full">
          {!!media?.url ? (
            <img
              src={media.url}
              alt={media?.name}
              className="object-cover w-14 h-14"
            />
          ) : null}
        </div>
      )
    },
  },
  {
    width: '10%',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:action')}</p>
        </TableTitleWrapperStyled>
      )
    },
  },
]

export const HOW_TO_CONTENT_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  ctaButtonName: '',
  ctaButtonNameInDutch: '',
}

export const howToContentSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  ctaButtonName: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  ctaButtonNameInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
})

export const HOW_TO_DETAIL_DEFAULT_VALUES = {
  no: 1,
  title: '',
  titleInDutch: '',
  description: '',
  descriptionInDutch: '',
  mediaId: 0,
  mediaMobileId: 0,
}

export const howToDetailSchema = z.object({
  no: z.number().min(1, {
    message: t('error:min_1') as string,
  }),
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: t('error:blog_title_too_long') as string,
    }),
  description: z
    .string()
    .trim()
    .max(500, {
      message: t('error:max_length_500') as string,
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .max(500, {
      message: t('error:max_length_500') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})

export const MOCK_TABLE_DATA = [
  {
    id: 432,
    no: 1,
    name: null,
    nameInDutch: null,
    title: 'vi ban update EN',
    titleInDutch: 'vi ban day NL',
    subTitle: null,
    subTitleInDutch: null,
    content: null,
    subContent: null,
    contentInDutch: null,
    subContentInDutch: null,
    milestone: null,
    type: 'ACADEMY_REASON',
    media: {
      id: 1305,
      url: 'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      name: 'AA6AD882-E559-4AEB-969C-49D7F671D577-2023-12-07-LxM.jpg',
      path: '1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      type: 'image/jpeg',
      bucket: 'llc-public-store',
      userId: 3,
      baseUrl:
        'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      category: null,
      createdAt: '2023-12-07T04:12:08.342Z',
      signedUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      updatedAt: '2023-12-07T04:12:08.342Z',
      cloudfrontUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
    },
    mediaId: 1305,
    mediaMobile: {
      id: 1305,
      url: 'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      name: 'AA6AD882-E559-4AEB-969C-49D7F671D577-2023-12-07-LxM.jpg',
      path: '1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      type: 'image/jpeg',
      bucket: 'llc-public-store',
      userId: 3,
      baseUrl:
        'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      category: null,
      createdAt: '2023-12-07T04:12:08.342Z',
      signedUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      updatedAt: '2023-12-07T04:12:08.342Z',
      cloudfrontUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
    },
    mediaMobileId: 1305,
    mediaDescription: 'Tat ca la vi ban EN',
    mediaDescriptionInDutch: 'Tat ca la vi ban NL',
    metaData: null,
    mediaTitle: null,
    createdAt: '2024-10-09T06:18:17.104Z',
    updatedAt: '2024-10-09T06:35:04.083Z',
  },
  {
    id: 430,
    no: 2,
    name: null,
    nameInDutch: null,
    title: 'vi em EN',
    titleInDutch: 'vi em NL',
    subTitle: null,
    subTitleInDutch: null,
    content: null,
    subContent: null,
    contentInDutch: null,
    subContentInDutch: null,
    milestone: null,
    type: 'ACADEMY_REASON',
    media: {
      id: 1305,
      url: 'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      name: 'AA6AD882-E559-4AEB-969C-49D7F671D577-2023-12-07-LxM.jpg',
      path: '1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      type: 'image/jpeg',
      bucket: 'llc-public-store',
      userId: 3,
      baseUrl:
        'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      category: null,
      createdAt: '2023-12-07T04:12:08.342Z',
      signedUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      updatedAt: '2023-12-07T04:12:08.342Z',
      cloudfrontUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
    },
    mediaId: 1305,
    mediaMobile: {
      id: 1305,
      url: 'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      name: 'AA6AD882-E559-4AEB-969C-49D7F671D577-2023-12-07-LxM.jpg',
      path: '1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      type: 'image/jpeg',
      bucket: 'llc-public-store',
      userId: 3,
      baseUrl:
        'https://llc-public-store.s3.amazonaws.com/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      category: null,
      createdAt: '2023-12-07T04:12:08.342Z',
      signedUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
      updatedAt: '2023-12-07T04:12:08.342Z',
      cloudfrontUrl:
        'https://d155rab1p54k9i.cloudfront.net/1701922326975-AA6AD882-E559-4AEB-969C-49D7F671D577.jpg',
    },
    mediaMobileId: 1305,
    mediaDescription: 'Tat ca la vi em EN',
    mediaDescriptionInDutch: 'Tat ca la vi em NL',
    metaData: null,
    mediaTitle: null,
    createdAt: '2024-10-09T06:16:17.518Z',
    updatedAt: '2024-10-09T08:19:55.022Z',
  },
]
