import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'
import {
  createEditTeacherBannerAction,
  createEditWhyUsContentAction,
  createReasonDetailAction,
  deleteReasonByIdAction,
  editReasonDetailByIdAction,
  getReasonDetailByIdAction,
  getTeacherBannerAction,
  getWhyUsContentAction,
  getWhyUsTableAction,
} from 'src/redux/actions'
import {
  ICommonContentItem,
  IWhyUsContentItem,
  IWhyUsTableItem,
} from '@interfaces'

interface ITeacherLandingPageState {
  bannerInformation: ICommonContentItem
  whyUsList: IWhyUsTableItem[]
  whyUsContent?: IWhyUsContentItem
  reasonDetail: ICommonContentItem
  loadings: Record<string, boolean | undefined>
}

const initialState: ITeacherLandingPageState = {
  bannerInformation: {
    title: '',
    titleInDutch: '',
    mediaDescription: '',
    mediaDescriptionInDutch: '',
    mediaId: 0,
    mediaMobileId: 0,
  },
  whyUsList: [],
  whyUsContent: {} as IWhyUsContentItem,
  reasonDetail: {} as ICommonContentItem,
  loadings: {},
}

const teacherLandingPage = createSlice({
  name: 'teacherLanding',
  initialState: initialState,
  reducers: {
    clearReasonDetail: (state) => {
      state.reasonDetail = {} as ICommonContentItem
    },
  },
  extraReducers: (builder) => {
    // getTeacherBannerAction
    builder.addCase(getTeacherBannerAction.pending, (state) => {
      state.loadings[`getTeacherBannerAction`] = true
    })
    builder.addCase(
      getTeacherBannerAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getTeacherBannerAction`] = false
        state.bannerInformation = action?.payload
      }
    )
    builder.addCase(getTeacherBannerAction.rejected, (state) => {
      state.loadings[`getTeacherBannerAction`] = false
    })

    // createEditTeacherBannerAction
    builder.addCase(createEditTeacherBannerAction.pending, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = true
    })
    builder.addCase(createEditTeacherBannerAction.fulfilled, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = false
    })
    builder.addCase(createEditTeacherBannerAction.rejected, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = false
    })

    // getWhyUsTableAction
    builder.addCase(getWhyUsTableAction.pending, (state) => {
      state.loadings[`getWhyUsTableAction`] = true
    })
    builder.addCase(
      getWhyUsTableAction.fulfilled,
      (state, action: PayloadAction<IWhyUsTableItem[]>) => {
        state.loadings[`getWhyUsTableAction`] = false
        state.whyUsList = action?.payload
      }
    )
    builder.addCase(getWhyUsTableAction.rejected, (state) => {
      state.loadings[`getWhyUsTableAction`] = false
    })

    // getWhyUsContentAction
    builder.addCase(getWhyUsContentAction.pending, (state) => {
      state.loadings[`getWhyUsContentAction`] = true
    })
    builder.addCase(
      getWhyUsContentAction.fulfilled,
      (state, action: PayloadAction<IWhyUsContentItem>) => {
        state.loadings[`getWhyUsContentAction`] = false
        state.whyUsContent = action?.payload
      }
    )
    builder.addCase(getWhyUsContentAction.rejected, (state) => {
      state.loadings[`getWhyUsContentAction`] = false
    })

    // createEditWhyUsContentAction
    builder.addCase(createEditWhyUsContentAction.pending, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = true
    })
    builder.addCase(createEditWhyUsContentAction.fulfilled, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = false
    })
    builder.addCase(createEditWhyUsContentAction.rejected, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = false
    })

    // getReasonDetailByIdAction
    builder.addCase(getReasonDetailByIdAction.pending, (state) => {
      state.loadings[`getReasonDetailByIdAction`] = true
    })
    builder.addCase(
      getReasonDetailByIdAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getReasonDetailByIdAction`] = false
        state.reasonDetail = action?.payload
      }
    )
    builder.addCase(getReasonDetailByIdAction.rejected, (state) => {
      state.loadings[`getReasonDetailByIdAction`] = false
    })

    // createReasonDetailAction
    builder.addCase(createReasonDetailAction.pending, (state) => {
      state.loadings[`createReasonDetailAction`] = true
    })
    builder.addCase(createReasonDetailAction.fulfilled, (state) => {
      state.loadings[`createReasonDetailAction`] = false
    })
    builder.addCase(createReasonDetailAction.rejected, (state) => {
      state.loadings[`createReasonDetailAction`] = false
    })

    // editReasonDetailByIdAction
    builder.addCase(editReasonDetailByIdAction.pending, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = true
    })
    builder.addCase(editReasonDetailByIdAction.fulfilled, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = false
    })
    builder.addCase(editReasonDetailByIdAction.rejected, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = false
    })

    // deleteReasonByIdAction
    builder.addCase(deleteReasonByIdAction.pending, (state) => {
      state.loadings[`deleteReasonByIdAction`] = true
    })
    builder.addCase(deleteReasonByIdAction.fulfilled, (state) => {
      state.loadings[`deleteReasonByIdAction`] = false
    })
    builder.addCase(deleteReasonByIdAction.rejected, (state) => {
      state.loadings[`deleteReasonByIdAction`] = false
    })
  },
})

export const teacherLandingPageActions = {
  ...teacherLandingPage.actions,
}

export const selectTeacherLandingPageLoading = (
  state: RootState,
  name: string
) => state.plans.loadings[`${name}Loading`]

export default teacherLandingPage.reducer
