import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { INotificationItem } from '@interfaces'
import {
  getListNotificationsAction,
  getNotificationUnreadAmountAction,
} from '../actions'
import { RootState } from '.'
import {
  INITIAL_PAGE,
  DEFAULT_LIMIT_ITEMS,
  DEFAULT_TOTAL_ITEMS,
} from '@configs'

interface INotificationState {
  listNotifications: INotificationItem[]
  currentPage: number
  limitNotifications: number
  totalNotifications: number
  unreadNotifications: number
  loadings: Record<string, boolean | undefined>
}

const initialState: INotificationState = {
  listNotifications: [],
  currentPage: INITIAL_PAGE,
  limitNotifications: DEFAULT_LIMIT_ITEMS,
  totalNotifications: DEFAULT_TOTAL_ITEMS,
  unreadNotifications: DEFAULT_TOTAL_ITEMS,
  loadings: {},
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },

    clearListNotifications: (state) => {
      state.listNotifications = []
      state.currentPage = INITIAL_PAGE
      state.limitNotifications = DEFAULT_LIMIT_ITEMS
      state.totalNotifications = DEFAULT_TOTAL_ITEMS
      state.loadings = {}
    },
  },
  extraReducers: (builder) => {
    // get list notifications
    builder.addCase(getListNotificationsAction.pending, (state) => {
      state.loadings[`getListNotificationsActionLoading`] = true
    })
    builder.addCase(getListNotificationsAction.fulfilled, (state, action) => {
      state.loadings[`getListNotificationsActionLoading`] = false
      state.listNotifications = action?.payload?.items
      state.currentPage = Number(action?.payload?.page)
      state.limitNotifications = Number(action?.payload?.limit)
      state.totalNotifications = action?.payload?.total
    })
    builder.addCase(getListNotificationsAction.rejected, (state) => {
      state.loadings[`getListNotificationsActionLoading`] = false
    })

    // get unread amount
    builder.addCase(getNotificationUnreadAmountAction.pending, (state) => {
      state.loadings[`getNotificationUnreadAmountActionLoading`] = true
    })
    builder.addCase(
      getNotificationUnreadAmountAction.fulfilled,
      (state, action) => {
        state.loadings[`getNotificationUnreadAmountActionLoading`] = false
        state.unreadNotifications = action.payload.unreadNumber
      }
    )
    builder.addCase(getNotificationUnreadAmountAction.rejected, (state) => {
      state.loadings[`getNotificationUnreadAmountActionLoading`] = false
    })
  },
})

export const notificationActions = {
  ...notificationSlice.actions,
}

export const selectNotificationLoading = (state: RootState, name: string) =>
  state.notification.loadings[`${name}Loading`]

export default notificationSlice.reducer
