import { EAboutUsPageTabs } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  RootState,
  getAboutUsPageSeoAction,
  updateAboutUsPageSeoAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Skeleton, message } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/common'
import { IAboutUsPageSeo } from 'src/interfaces/aboutus-management'
import { EMediaCategory } from 'src/interfaces/gallery'
import MetaTab from 'src/pages/blog/content/MetaTab'
import {
  getCoursePageSeoAction,
  updateCoursePageSeoAction,
} from 'src/redux/actions/course'
import { z } from 'zod'

interface IAboutUsPageSeoProps {
  activeTab: string
}

const AboutUsPageSeo = ({ activeTab }: IAboutUsPageSeoProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const dispatch = useAppDispatch()

  const detailAboutUsPageSeo = useAppSelector(
    (state: RootState) => state.course?.aboutUsPageSeo
  )

  const isGetAboutUsPageSeoActionLoading = useAppSelector(
    (state: RootState) => state.course?.loadings?.getCoursePageSeoActionLoading
  )

  const isUpdateAboutUsPageSeoActionLoading = useAppSelector(
    (state: RootState) =>
      state.course?.loadings?.updateCoursePageSeoActionLoading
  )

  const aboutUsPageSeoSchema = z.object({
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    schemaMarkup: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    trigger,
    reset,
  } = useForm<IAboutUsPageSeo>({
    defaultValues: {
      metaTitle: detailAboutUsPageSeo?.metaTitle ?? '',
      metaDescription: detailAboutUsPageSeo?.metaDescription ?? '',
      metaImage: detailAboutUsPageSeo?.metaImage ?? '',
      canonical: detailAboutUsPageSeo?.canonical ?? '',
      schemaMarkup: detailAboutUsPageSeo?.schemaMarkup ?? 'schema mark up',
      keywords: detailAboutUsPageSeo?.keywords ?? '',
    },
    resolver: zodResolver(aboutUsPageSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const handleClickCancel = () => {
    reset({
      metaTitle: detailAboutUsPageSeo?.metaTitle ?? '',
      metaDescription: detailAboutUsPageSeo?.metaDescription ?? '',
      canonical: detailAboutUsPageSeo?.canonical ?? '',
      // schemaMarkup: detailAboutUsPageSeo?.schemaMarkup ?? '',
      metaImage: detailAboutUsPageSeo?.metaImage ?? '',
      keywords: detailAboutUsPageSeo?.keywords ?? '',
    })
  }

  const onSubmit = async (data: IAboutUsPageSeo) => {
    try {
      const response = await dispatch(updateCoursePageSeoAction(data)).unwrap()

      if (response.success) {
        message.success(response?.message ?? '')
      }
    } catch (error: any) {
      message.error(error?.message ?? '')
    }
  }

  const handleClickSave = () => {
    handleSubmit(onSubmit)()
  }

  useEffect(() => {
    if (activeTab === EAboutUsPageTabs.ABOUT_US_PAGE_SEO) {
      const getAboutUsPageSeo = async () => {
        try {
          const response = await dispatch(getCoursePageSeoAction()).unwrap()

          if (response.success) {
            setValue('metaTitle', response.data?.metaTitle)
            setValue('metaDescription', response.data?.metaDescription)
            setValue('metaImage', response.data?.metaImage)
            setValue('canonical', response.data?.canonical)
            // setValue('schemaMarkup', response.data?.schemaMarkup)
            setValue('keywords', response.data?.keywords)
          }
        } catch (error: any) {
          message.error(error?.message ?? '')
        }
      }
      getAboutUsPageSeo()
    }
  }, [activeTab])
  return (
    <Fragment>
      {isGetAboutUsPageSeoActionLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <div className="bg-white">
          <MetaTab
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setIsUploading={setIsUploading}
            isShowSelectMedia
            category={EMediaCategory.CONTENT}
          />

          <div className="term-policy-action mr-5 pb-5 flex items-center justify-end gap-6">
            <Button
              type="default"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickCancel()
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickSave()
              }}
              loading={
                isGetAboutUsPageSeoActionLoading ||
                isUpdateAboutUsPageSeoActionLoading
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default AboutUsPageSeo
