import { useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import { CommonTeacherContentWrapperStyled } from '../../styled'
import { Button, SharedStyledTableWrapper, SharedTable } from 'src/common'
import { WHY_US_TABLE_COLUMNS } from './configs'
import { IWhyUsTableItem } from '@interfaces'
import { WhyUsContentModal } from './WhyUsContentModal'
import { ReasonDetailModal } from './ReasonDetailModal'
import {
  deleteReasonByIdAction,
  getWhyUsTableAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { ETeacherLandingPageTabs } from '@configs'
import { message } from 'antd'

const WhyUsTableButton = ({
  onEditContent,
  onAddNew,
}: {
  onEditContent: () => void
  onAddNew: () => void
}) => {
  return (
    <WhyUsTableButtonStyled>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !bg-amber-500 !border-none"
        icon={<EditOutlined className="!text-[16px]" />}
        onClick={onEditContent}
      >
        {t('teacher:edit_content')}
      </Button>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !border-none"
        icon={<PlusOutlined className="!text-[16px]" />}
        onClick={onAddNew}
      >
        {t('teacher:add_new')}
      </Button>
    </WhyUsTableButtonStyled>
  )
}

const WhyUsTableButtonStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`

export const WhyUsTable = ({ activeTab }: { activeTab?: string }) => {
  const dispatch = useAppDispatch()

  const [openModalWhyUsContent, setOpenModalWhyUsContent] = useState(false)
  const [openReasonDetail, setOpenReasonDetail] = useState<
    boolean | number | string
  >(false)

  const { whyUsList, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isGettingWhyUsTable = loadings['getWhyUsTableAction']

  const handleDeleteReasonById = async (id: number | string) => {
    try {
      const res = await dispatch(deleteReasonByIdAction(id)).unwrap()
      if (res) {
        message.success(res?.message)
        dispatch(getWhyUsTableAction())
      }
    } catch (error: any) {
      message.error(error?.message)
    }
  }

  const tableColumns = useMemo(
    () =>
      WHY_US_TABLE_COLUMNS.map((column) => ({
        ...column,
        ...(column.key === 'action' && {
          render: (_: any, record: IWhyUsTableItem) => (
            <div className="w-full flex items-center gap-4 justify-center">
              <EyeOutlined
                onClick={() => setOpenReasonDetail(record?.id)}
                className="!text-[16px] cursor-pointer"
              />
              <DeleteOutlined
                className="!text-[16px] cursor-pointer !text-red-600"
                onClick={() => handleDeleteReasonById(record?.id)}
              />
            </div>
          ),
        }),
      })),
    []
  )

  useEffect(() => {
    if (ETeacherLandingPageTabs.TEACHER_WHY_US.includes(activeTab ?? '')) {
      dispatch(getWhyUsTableAction())
    }
  }, [activeTab, dispatch])
  return (
    <>
      {!!openReasonDetail ? (
        <ReasonDetailModal
          open={true}
          onClose={() => setOpenReasonDetail(false)}
          {...(typeof openReasonDetail === 'number' && {
            reasonId: openReasonDetail,
          })}
        />
      ) : null}
      {openModalWhyUsContent ? (
        <WhyUsContentModal
          open={true}
          onClose={() => setOpenModalWhyUsContent(false)}
        />
      ) : null}
      <CommonTeacherContentWrapperStyled>
        <WhyUsTableButton
          onAddNew={() => setOpenReasonDetail(true)}
          onEditContent={() => setOpenModalWhyUsContent(true)}
        />
        <SharedStyledTableWrapper>
          <SharedTable
            columns={tableColumns}
            dataSource={whyUsList}
            hasIndexColumn={false}
            tableProps={{
              loading: { spinning: !!isGettingWhyUsTable, size: 'large' },
            }}
            sticky
          />
        </SharedStyledTableWrapper>
      </CommonTeacherContentWrapperStyled>
    </>
  )
}
