import { useMemo, useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import { CommonTeacherContentWrapperStyled } from '../../styled'
import { Button, SharedStyledTableWrapper, SharedTable } from 'src/common'
import { HOW_TO_TABLE_COLUMNS, MOCK_TABLE_DATA } from './configs'
import { IWhyUsTableItem } from '@interfaces'
import { HowToContentModal } from './HowToContentModal'
import { HowToDetailModal } from './HowToDetailModal'

const HowToTableButton = ({
  onEditContent,
  onAddNew,
}: {
  onEditContent: () => void
  onAddNew: () => void
}) => {
  return (
    <HowToTableButtonStyled>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !bg-amber-500 !border-none"
        icon={<EditOutlined className="!text-[16px]" />}
        onClick={onEditContent}
      >
        {t('teacher:edit_content')}
      </Button>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !border-none"
        icon={<PlusOutlined className="!text-[16px]" />}
        onClick={onAddNew}
      >
        {t('teacher:add_new')}
      </Button>
    </HowToTableButtonStyled>
  )
}

const HowToTableButtonStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`

export const HowToTable = ({ activeTab }: { activeTab?: string }) => {
  const [openModalHowToContent, setOpenModalHowToContent] = useState(false)
  const [openHowToDetail, setOpenHowToDetail] = useState<
    boolean | number | string
  >(false)

  const tableColumns = useMemo(
    () =>
      HOW_TO_TABLE_COLUMNS.map((column) => ({
        ...column,
        ...(column.key === 'action' && {
          render: (_: any, record: any) => (
            <div className="w-full flex items-center gap-4 justify-center">
              <EyeOutlined
                onClick={() => setOpenHowToDetail(record?.id)}
                className="!text-[16px] cursor-pointer"
              />
              <DeleteOutlined
                className="!text-[16px] cursor-pointer !text-red-600"
                onClick={() => {}}
              />
            </div>
          ),
        }),
      })),
    []
  )
  return (
    <>
      {!!openHowToDetail ? (
        <HowToDetailModal
          open={true}
          onClose={() => setOpenHowToDetail(false)}
          onSubmit={() => {}}
          {...(typeof openHowToDetail === 'number' && {
            howToId: openHowToDetail,
          })}
        />
      ) : null}
      {openModalHowToContent ? (
        <HowToContentModal
          open={true}
          onClose={() => setOpenModalHowToContent(false)}
          onSubmit={() => {}}
        />
      ) : null}
      <CommonTeacherContentWrapperStyled>
        <HowToTableButton
          onAddNew={() => setOpenHowToDetail(true)}
          onEditContent={() => setOpenModalHowToContent(true)}
        />
        <SharedStyledTableWrapper>
          <SharedTable
            columns={tableColumns}
            dataSource={MOCK_TABLE_DATA}
            //   paginationProps={{
            //     total: 0,
            //     pageSize: 0,
            //     current: 0,
            //     showSizeChanger: false,
            //     onChange(page) {},
            //   }}
            //   loading={{
            //     spinning: isLoading || !invoiceItem,
            //     size: 'large',
            //   }}
            hasIndexColumn={false}
            sticky
          />
        </SharedStyledTableWrapper>
      </CommonTeacherContentWrapperStyled>
    </>
  )
}
